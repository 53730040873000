import React from 'react'
import Link from 'gatsby-link'

class ListLink extends React.Component {
    constructor() {
        super()
        this.state = {
            isHovered: false,
            window: {
                width: 0,
                height: 0
            }
        }
    }

    componentDidMount() {
        this.getWindowState();
        window.addEventListener('resize', this.getWindowState);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getWindowState);
    }

    getWindowState = () => {
        this.setState({
            window: {
            width: window.innerWidth,
            height: window.innerHeight
            }
        })
    }

    handleMouseEnter = () => {
        this.setState({isHovered: true})
    }

    handleMouseLeave = () => {
        this.setState({isHovered: false})
    }

    render() {
        const {isHovered} = this.state;
        const isCurrentPage = this.props.isCurrentPage;
        const {window} = this.state;

        const styles = {
            listItem: {
                display: 'inline-block',
                fontWeight: 500,
                fontSize: 16,
                textAlign: 'center',
                marginBottom: 0,
                marginTop: 0,
                paddingTop: 12,
                paddingBottom: 12,
                position: 'relative',
                boxShadow: isHovered || isCurrentPage ? 'inset 0px -3px ' + this.props.borderColor : 'none',
            },
            link: {
                textDecoration: 'none',
                color: '#fff',
                backgroundColor: isHovered || isCurrentPage ? '#5c6b41' : 'inherit',
                paddingTop: 14,
                paddingBottom: 14,
                paddingRight: window.width > 600 ? 35 : 18,
                paddingLeft: window.width > 600 ? 35 : 18,
                marginBottom: 0,
                marginTop: 0,
                transition: 'background-color .1s cubic-bezier(.4,0,.2,1)',
            }
        }

        return(
            <li style={styles.listItem} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <Link to={this.props.to} style={styles.link}>{this.props.children}</Link>
            </li>
        )
    }
}

export default ListLink