import React from 'react'
import Helmet from 'react-helmet'

import Header from './Header'
import Footer from './Footer'

class Layout extends React.Component {
  constructor() {
    super();
    this.state = {
      currentPage: null,
    }
  }

  setCurrentPage = (page) => {
    this.setState({currentPage: page});
  }

  render() {    
    return (
      <div>
        <Helmet
          title={this.props.metadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        />
        <Header currentPage={this.props.currentPage} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 40,
            minHeight: '100vh'
          }}
        >
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
