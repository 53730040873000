import React from 'react';
import Link from 'gatsby-link';

import logo from '../assets/images/logo.png';
import MenuList from './MenuList';

const menuItems = ["Home", "About Me", "Contact", "Blog", "Benefits", "FAQ"];

export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      window: {
        width: 0,
        height: 0
      }
    }
  }

  componentDidMount() {
    this.getWindowState();
    window.addEventListener('resize', this.getWindowState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowState);
  }

  getWindowState = () => {
    this.setState({
        window: {
        width: window.innerWidth,
        height: window.innerHeight
        }
    })
  }

  render() {
    const {window} = this.state;

    const styles = {
      container: {
        backgroundColor: '#f9fde4'
      },
      preHeader: {
        maxWidth: window.width > 600 ? 350 : 250,
        margin: '0 auto'
      },
      logo: {
        margin: 10,
        width: '100%'
      },
      navigation: {
        backgroundColor: '#7a8e57'
      },
      navContainer: {
        maxWidth: 940,
        margin: '0 auto',
        whiteSpace: 'nowrap',
        overflowX: 'auto'
      },
      mainMenu: {
        listStyle: 'none',
        margin: 0,
    
      }
    }

    return(
      <header style={styles.container}>
        <div style={styles.preHeader}>
          <Link to="/">
            <img src={logo} style={styles.logo} alt="logo" />
          </Link>
        </div>
        <nav style={styles.navigation}>
          <div style={styles.navContainer}>
            <MenuList items={menuItems} currentPage={this.props.currentPage} />
          </div>
        </nav>
      </header>
    )
  }
}