import React from 'react'

var d = new Date();
var n = d.getFullYear();

export default class Footer extends React.Component {
  render() {
    const styles = {
      footer: {
        borderTop: '3px solid rgb(122, 142, 87)',
        backgroundColor: '#fafafa'
      },
      copyright: {
        fontSize: 14,
        textAlign: 'center',
        position: 'relative',
        margin: 10,
        color: '#757575'
      }
    }

    return(
      <footer style={styles.footer}>
        <div>
          <div style={styles.copyright}>© Máire McAndrew Counselling & Psychotherapy {n}</div>
        </div>
      </footer>
    )
  }
}