import React from 'react'
import ListLink from './ListLink'

const MenuList = (props) => {
    const menuList = {
        listStyle: 'none',
        display: 'block',
        margin: '0 auto',
        padding: 0,
        textAlign: 'center'
    }

    let borderColor;

    const currentPage = props.currentPage

    return(
        <ul style={menuList} >
            {props.items.map((item, index) => {
                let link = "/" + item.toLowerCase().replace(/\s/g, '')

                if(item.toLowerCase() === "home") {
                  link = "/";
                }
                
                return <ListLink key={item} to={link} borderColor={borderColor} isCurrentPage={props.items[index] === currentPage ? true : false} >{item}</ListLink>
                }
            )}
        </ul>
    )
}

export default MenuList